import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import mjson from '../../local-json/Common';

import AOS from 'aos';
import "aos/dist/aos.css";

import MetaTags from '../components/MetaTags';

function PriceComponent()
{
    let [lurl, setApiurl] = React.useState(null);
    let [post, setPost] = React.useState(null);
    let logurl=[];
    React.useEffect(() => 
    {
        if(Object.keys(mjson).length>0)
        {
            setApiurl(mjson.price_api)
            logurl=mjson.price_api
            
        }
         
        if(logurl!=null)
        {
              
            axios.get(logurl).then((response) => 
            {
                let res=response.data;
                if(res.type=='success')
                {
                    setPost(res.data[0])
                }
            });
        }
        
        AOS.init();
        

    },[]);
    if(post==null) return '';

    AOS.refresh();
  return(
    <main id="main">
        <MetaTags
        title='KalendarDay Pricing | Simple, Transparent Plans'
        description='Discover KalendarDay’s straightforward pricing options. No hidden fees—just simple, transparent plans to help you manage time, budgets, projects, and more effectively.'
        image='assets/img/favicon.png'
        name='KalendarDay'
    />
    <section id="pricing" class="pricing">
        <div class="container" data-aos="fade-up">
        <div class="section-title">
            <h2>Simple, Transparent pricing </h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6 offset-lg-2" data-aos="fade-up" data-aos-delay="100">
            <div class="box">
                <img src="assets/img/visa-brandmark.png" class="float-end" alt="mastercard" height="41" />
                <h3>Free</h3>
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom"></div>
                <ul>
                <li>
                    <img src="assets/img/Group31.svg" class="float-start" alt="" /> 24/7 Support
                </li>
                <li>
                    <img src="assets/img/Group31.svg" class="float-start" alt=""/> Two-factor authentication
                </li>
                <li>
                    <img src="assets/img/Group31.svg" class="float-start" alt="" /> ios & android app
                </li>
                <li>
                    <img src="assets/img/Group31.svg" class="float-start" alt="" /> Project Limit
                </li>
                <li class="na">
                    <img src="assets/img/Group30.svg" class="float-start" alt="" /> Unlimited free viewers
                </li>
                <li class="na">
                    <img src="assets/img/Group30.svg" class="float-start" alt="" /> Storage
                </li>
                </ul>
                <a href="sign-up.html" class="buy-btn">Start 3 Months Free</a>
            </div>
            </div>
            <div class="col-lg-4 col-sm-6 mt-4 mt-sm-0" data-aos="fade-up" data-aos-delay="200">
              
                <div class="box featured">
                    <img src="assets/img/visa-brandmark.png" class="float-end" alt="mastercard" height="41"/>
                    <h3>${post.per_usr_price} / User / Month </h3>
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom"></div>
                    <ul>
                        {post.hasOwnProperty('plan_features') && post.plan_features.map(function(k,v){
                            var img=(k.value=='0')?'assets/img/Group30.svg':'assets/img/Group31.svg'
                           return <li><img src={img} class="float-start" alt="" />{k.slug}</li>
                        })}
                    {/* <li>
                        <img src="assets/img/Group31.svg" class="float-start" alt="" /> 24/7 Support
                    </li>
                    <li>
                        <img src="assets/img/Group31.svg" class="float-start" alt="" /> Two-factor authentication
                    </li>
                    <li>
                        <img src="assets/img/Group31.svg" class="float-start" alt="" /> ios & android app
                    </li>
                    <li>
                        <img src="assets/img/Group31.svg" class="float-start" alt="" /> Project Limit
                    </li>
                    <li class="na">
                        <img src="assets/img/Group30.svg" class="float-start" alt="" /> Unlimited free viewers
                    </li>
                    <li class="na">
                        <img src="assets/img/Group30.svg" class="float-start" alt="" /> Storage
                    </li> */}
                    </ul>
                    <a href="sign-up.html" class="buy-btn float-center">Monthly</a>
                </div>
            </div>
        </div>
        </div>
    </section>
    {/*<section id="faq" class="faq section">
        <div class="container" data-aos="fade-up">
        <div class="section-title">
            <h2>Frequently Asked Questions</h2>
            <p>Trusted by thousands of organizations across the globe</p>
        </div>
        <div class="faq-list">
            <ul>
            <li data-aos="fade-up" data-aos-delay="200">
                <a data-bs-toggle="collapse" data-bs-target="#faq-list-1" class="collapsed">How is flowbase this good! <i class="bx bx-plus icon-show"></i>
                <i class="bx bx-minus icon-close"></i>
                </a>
                <div id="faq-list-1" class="collapse" data-bs-parent=".faq-list">
                <p> Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui. </p>
                </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="200">
                <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" class="collapsed">Can we actually customise everything? <i class="bx bx-plus icon-show"></i>
                <i class="bx bx-minus icon-close"></i>
                </a>
                <div id="faq-list-2" class="collapse" data-bs-parent=".faq-list">
                <p> Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui. </p>
                </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
                <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" class="collapsed">So you're saying I don't have to build an FAQ everytime? <i class="bx bx-plus icon-show"></i>
                <i class="bx bx-minus icon-close"></i>
                </a>
                <div id="faq-list-3" class="collapse" data-bs-parent=".faq-list">
                <p> Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar elementum integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque eu tincidunt. Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis sed odio morbi quis </p>
                </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="400">
                <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" class="collapsed">Are all the classes this damn clean? <i class="bx bx-plus icon-show"></i>
                <i class="bx bx-minus icon-close"></i>
                </a>
                <div id="faq-list-4" class="collapse" data-bs-parent=".faq-list">
                <p> Molestie a iaculis at erat pellentesque adipiscing commodo. Dignissim suspendisse in est ante in. Nunc vel risus commodo viverra maecenas accumsan. Sit amet nisl suscipit adipiscing bibendum est. Purus gravida quis blandit turpis cursus in. </p>
                </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="500">
                <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" class="collapsed">How is flowbase this good! <i class="bx bx-plus icon-show"></i>
                <i class="bx bx-minus icon-close"></i>
                </a>
                <div id="faq-list-5" class="collapse" data-bs-parent=".faq-list">
                <p> Laoreet sit amet cursus sit amet dictum sit amet justo. Mauris vitae ultricies leo integer malesuada nunc vel. Tincidunt eget nullam non nisi est sit amet. Turpis nunc eget lorem dolor sed. Ut venenatis tellus in metus vulputate eu scelerisque. </p>
                </div>
            </li>
            </ul>
        </div>
        </div>
    </section>*/}
   </main>
  );
}
export default PriceComponent;