import React from 'react';
import {Link} from 'react-router-dom';
import mjson from '../../local-json/Common';
import $ from 'jquery';

function FooterComponent(props){
  <style>
   
  </style>
  
    return(
        <footer id="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row footerrow">
              
                <div className="col-md-12 col-lg-4  mb-5 mb-lg-0 footer-contact">
                  <Link to="/" className="logo me-auto">
                      <img src="assets/img/Black.png" alt="kelanderday" />
                  </Link>
                  <p>KalendarDay uses the most advanced technologies and programming to let you control your Company’s Time, Budget, Projects, Proposals, Future work, Communications, and much more.</p>
                </div>

                <div className="col-3 col-sm-3 col-lg-2 footer-links footerLinkDiv">
                  <div className="">
                    <h3 className="">Product</h3>
                    <ul className="">
                      {/* <li> <Link to="payment">Product overview</Link></li> */}
                      <li> <Link to="price">Pricing</Link></li>
                    </ul>
                  </div>
                </div>

                <div className="col-3 col-sm-3 col-lg-2 footer-links footerLinkDiv">
                  <div className="">
                    <h3 className="">Company</h3>
                    <ul className="">
                      <li> <Link to="/#">Contact us</Link></li>
                    </ul>
                  </div>
                </div>
                <div className="col-3 col-sm-3 col-lg-2 pt-sm-0 footer-links footerLinkDiv">
                  <div className="">
                    <h3 className="">Resource</h3>
                    <ul className="">
                      <li> <Link to="/#">Support</Link></li>
                      <li> <Link onClick={() => window.location.replace("/#testimonials")}>Reviews</Link></li>
                      <li> <Link to="/faq">FAQ</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container d-md-flex py-2 py-lg-4 footer-bottom">
            <div className="me-md-auto text-md-start pt-4 pt-lg-0 order-1 order-lg-0">
              <div className="credits">
                  <ul>
                    {/* <li> <Link to="/#">Status</Link></li> */}
                    <li> <Link to="/#">Security</Link></li>
                    <li> <Link to="/#">Terms and privacy</Link></li>
                    <li> <Link to="/#">Privacy policy</Link></li>
                  </ul>
              </div>
            </div>
            <div className="social-links text-center text-md-start text-lg-end pt-4 pb-0 pb-md-4 pb-lg-0 pt-md-0 order-0 order-lg-1">
            
            {props.socialArr.length>0 && props.socialArr.map(function(k,v)
            {
              var mclass='bx bxl-'+k[0];
              return <a target="_blank" key={v} href={k[1]} className={k[0]}><i className={mclass}></i></a>
            })}
            </div>
          </div>
        </footer>
    );
}
export default FooterComponent;